import { isNotNullOrUndefined, isNullOrUndefined, deepCompare } from '@app/utils';
import { useOnOffState } from '@lib/components';
import {
  Box,
  Chip,
  Collapse,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import { Delete, DotsVertical, Pencil } from 'mdi-material-ui';
import { memo, useMemo, useState, FC, MouseEvent as ReactMouseEvent, useCallback, Fragment, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAsync } from 'react-use';
import { AsyncState } from 'react-use/lib/useAsyncFn';

import { DeleteDialog } from './DeleteDialog';
import { RenameDialog } from './RenameDialog';

import { reportError } from '@/ErrorLogger';
import { useUserActionTracker } from '@/Hooks/Analytics';
import { useDependency } from '@/Hooks/DependencyHook';
import { useEnqueueSnackbar } from '@/Hooks/Snackbar';
import { useIsSmallDevice } from '@/Hooks/Styles/IsSmallDevice';
import { textSxProps } from '@/Hooks/Styles/TextStyle';
import { ConditionData } from '@/Models/ConditionStorage';
import { RouteDefinitions } from '@/Pages/RouteDefinitions';
import { debounce } from '@/Utils/Debounce';

const AnalyticsPlaceName = 'サイドメニュー';
const iconMinWidth = '30px';

type SavedConditionsProps = {
  open: boolean;
  onItemClick: () => void;
};

const reportPlace = '@/Pages/bukken/chintai/SideMenuPartial/SavedConditions.tsx';
const SelectedConditionQueryName = 'condId';

const SavedConditionsContainer: FC<SavedConditionsProps> = memo(({ open, onItemClick }) => {
  const conditionStorageService = useDependency('conditionStorageService');
  const newArrivalBukkenCountService = useDependency('newArrivalBukkenCountService');
  const location = useLocation();
  const tracker = useUserActionTracker(AnalyticsPlaceName);
  const [savedConditions, setSavedConditions] = useState<ConditionData[]>([]);
  const navigate = useNavigate();
  const enqueueSnackbar = useEnqueueSnackbar();

  useEffect(() => {
    return conditionStorageService.subscribeConditions((conditions: ConditionData[]) => {
      setSavedConditions(conditions);
    });
  }, [conditionStorageService]);

  // 新着物件の件数を取得
  const newArrivalState = useAsync(async (): Promise<ReadonlyMap<string, number>> => {
    return newArrivalBukkenCountService.getIdToNewArrivalCountMap(savedConditions);
  }, [newArrivalBukkenCountService, savedConditions]);

  const onConditionClick = debounce(
    useCallback(
      (id: string): void => {
        const selectedCondition = savedConditions.find(it => it.conditionId === id);
        if (isNullOrUndefined(selectedCondition)) {
          reportError(`サイドメニューで選択された検索条件のID(${id})が存在しません`, reportPlace, true);
          return;
        }
        tracker('賃借検索条件呼び出し', '', 1);
        navigate(
          {
            pathname: RouteDefinitions.chintaiBukkenSearch.path,
            search: `${selectedCondition.savedQuery}&${SelectedConditionQueryName}=${selectedCondition.conditionId}`,
          },
          { state: { cacheTimestamp: Date.now() } }
        );
        onItemClick();
      },
      [navigate, onItemClick, savedConditions, tracker]
    )
  );

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [selectedItem, setSelectedItem] = useState<ConditionData | null>(null);
  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
    setSelectedItem(null);
  }, []);

  const onMenuClick = useCallback((event: ReactMouseEvent<HTMLButtonElement>, selectedCondition: ConditionData) => {
    setAnchorEl(event.currentTarget);
    setSelectedItem(selectedCondition);
  }, []);

  const [isRenameDialogOpen, { setTrue: onRenameDialogOpen, setFalse: onRenameDialogClose }] = useOnOffState(false);
  const [renameDefaultValue, setRenameDefaultValue] = useState('');
  const openRenameDialog = useCallback(
    (e: ReactMouseEvent<HTMLLIElement, MouseEvent>) => {
      setRenameDefaultValue(selectedItem?.name ?? '');
      onRenameDialogOpen();
      e.currentTarget.blur();
    },
    [onRenameDialogOpen, selectedItem?.name]
  );
  const handleRename = useCallback(
    async (newName: string) => {
      if (isNullOrUndefined(selectedItem)) {
        return;
      }
      const isSuccess = await conditionStorageService.renameCondition(selectedItem.conditionId, newName);
      const message = isSuccess
        ? '検索条件名を変更しました'
        : '検索条件名の変更に失敗しました。時間をおいて再度お試しください。';
      enqueueSnackbar(message, { variant: isSuccess ? 'success' : 'error' });
      setRenameDefaultValue('');
      handleMenuClose();
    },
    [conditionStorageService, enqueueSnackbar, handleMenuClose, selectedItem]
  );

  const [isDeleteDialogOpen, { setTrue: onDeleteDialogOpen, setFalse: onDeleteDialogClose }] = useOnOffState(false);
  const openDeleteDialog = useCallback(
    (e: ReactMouseEvent<HTMLLIElement, MouseEvent>) => {
      onDeleteDialogOpen();
      e.currentTarget.blur();
    },
    [onDeleteDialogOpen]
  );
  const handleDelete = useCallback(async () => {
    if (isNullOrUndefined(selectedItem)) {
      return;
    }
    handleMenuClose();
    const isSuccess = await conditionStorageService.deleteCondition(selectedItem.conditionId);
    const message = isSuccess
      ? '検索条件を削除しました'
      : '検索条件の削除に失敗しました。時間をおいて再度お試しください。';
    enqueueSnackbar(message, { variant: isSuccess ? 'success' : 'error' });
    if (isSuccess) {
      tracker('賃借検索条件削除', '', 1);
    }
  }, [conditionStorageService, enqueueSnackbar, handleMenuClose, selectedItem, tracker]);

  const queryConditionId = useMemo(() => {
    const queries = new URLSearchParams(location.search);
    return queries.get(SelectedConditionQueryName);
  }, [location.search]);

  return (
    <SavedConditionsPresenter
      open={open}
      savedConditions={savedConditions}
      newArrivalState={newArrivalState}
      onConditionClick={onConditionClick}
      anchorEl={anchorEl}
      selectedItem={selectedItem}
      onMenuClick={onMenuClick}
      isRenameDialogOpen={isRenameDialogOpen}
      onRenameDialogClose={onRenameDialogClose}
      renameDefaultValue={renameDefaultValue}
      openRenameDialog={openRenameDialog}
      handleRename={handleRename}
      isDeleteDialogOpen={isDeleteDialogOpen}
      onDeleteDialogClose={onDeleteDialogClose}
      openDeleteDialog={openDeleteDialog}
      handleDelete={handleDelete}
      queryConditionId={queryConditionId}
      handleMenuClose={handleMenuClose}
    />
  );
}, deepCompare);

export { SavedConditionsContainer as SavedConditions };

type SavedConditionsPresenterProps = {
  open: boolean;
  savedConditions: ConditionData[];
  newArrivalState: AsyncState<ReadonlyMap<string, number>>;
  onConditionClick: (id: string) => void;
  anchorEl: Element | null;
  selectedItem: ConditionData | null;
  onMenuClick: (event: ReactMouseEvent<HTMLButtonElement>, selectedCondition: ConditionData) => void;
  isRenameDialogOpen: boolean;
  onRenameDialogClose: () => void;
  renameDefaultValue: string;
  openRenameDialog: (e: ReactMouseEvent<HTMLLIElement, MouseEvent>) => void;
  handleRename: (newName: string) => Promise<void>;
  isDeleteDialogOpen: boolean;
  onDeleteDialogClose: () => void;
  openDeleteDialog: (e: ReactMouseEvent<HTMLLIElement, MouseEvent>) => void;
  handleDelete: () => Promise<void>;
  queryConditionId: string | null;
  handleMenuClose: () => void;
};

export const SavedConditionsPresenter: FC<SavedConditionsPresenterProps> = memo(
  ({
    open,
    savedConditions,
    newArrivalState,
    onConditionClick,
    anchorEl,
    selectedItem,
    onMenuClick,
    isRenameDialogOpen,
    onRenameDialogClose,
    renameDefaultValue,
    openRenameDialog,
    handleRename,
    isDeleteDialogOpen,
    onDeleteDialogClose,
    openDeleteDialog,
    handleDelete,
    queryConditionId,
    handleMenuClose,
  }) => {
    return (
      <Fragment>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {savedConditions.map(it => {
              const newArrivalCount = newArrivalState.value?.get(it.conditionId) ?? 0;
              return (
                <SavedConditionItem
                  key={it.conditionId}
                  onConditionClick={onConditionClick}
                  newArrivalCount={newArrivalCount}
                  isQueryMatched={queryConditionId === it.conditionId}
                  isSelected={it.conditionId === selectedItem?.conditionId}
                  condition={it}
                  onMenuClick={onMenuClick}
                />
              );
            })}
          </List>
        </Collapse>
        <SavedConditionsMenu
          anchorEl={anchorEl}
          onMenuClose={handleMenuClose}
          openRenameDialog={openRenameDialog}
          openDeleteDialog={openDeleteDialog}
        />
        <RenameDialog
          isDialogOpen={isRenameDialogOpen}
          onDialogClose={onRenameDialogClose}
          onSubmit={handleRename}
          defaultValue={renameDefaultValue}
        />
        <DeleteDialog
          isDialogOpen={isDeleteDialogOpen}
          onDialogClose={onDeleteDialogClose}
          onSubmit={handleDelete}
          selectedConditionName={selectedItem?.name ?? ''}
        />
      </Fragment>
    );
  },
  deepCompare
);

type SavedConditionProps = {
  anchorEl: Element | null;
  onMenuClose: () => void;
  openRenameDialog: (e: ReactMouseEvent<HTMLLIElement, MouseEvent>) => void;
  openDeleteDialog: (e: ReactMouseEvent<HTMLLIElement, MouseEvent>) => void;
};
const SavedConditionsMenu: FC<SavedConditionProps> = memo(
  ({ anchorEl, onMenuClose, openRenameDialog, openDeleteDialog }) => {
    return (
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={isNotNullOrUndefined(anchorEl)}
        onClose={onMenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={openRenameDialog}>
          <ListItemIcon
            sx={{
              minWidth: iconMinWidth,
            }}
          >
            <Pencil />
          </ListItemIcon>
          条件名
        </MenuItem>
        <MenuItem onClick={openDeleteDialog}>
          <ListItemIcon
            sx={{
              minWidth: iconMinWidth,
            }}
          >
            <Delete />
          </ListItemIcon>
          削除
        </MenuItem>
      </Menu>
    );
  }
);

type SavedConditionItemProps = {
  condition: ConditionData;
  onConditionClick: (id: string) => void;
  onMenuClick: (event: ReactMouseEvent<HTMLButtonElement>, selectedCondition: ConditionData) => void;
  newArrivalCount: number;
  isQueryMatched: boolean;
  isSelected: boolean;
};

const SavedConditionItem: FC<SavedConditionItemProps> = memo(props => {
  const [isHovered, { setTrue: setIsHoveredTrue, setFalse: setIsHoveredFalse }] = useOnOffState(false);
  const isSmallDevice = useIsSmallDevice();
  return (
    <ListItemButton
      data-testclass="saveConditionButtons"
      onClick={() => props.onConditionClick(props.condition.conditionId)}
      onMouseEnter={setIsHoveredTrue}
      onMouseLeave={setIsHoveredFalse}
      sx={
        props.isQueryMatched
          ? {
              backgroundColor: 'rgba(255,255,255,0.5)',
              borderLeft: theme => `solid 4px ${theme.palette.accent.main}`,
            }
          : { borderLeft: 'solid 4px transparent' }
      }
    >
      <ListItemText inset sx={{ paddingLeft: '26px' }}>
        <Box display="flex" alignItems="center" sx={{ minHeight: '1.75rem' }}>
          <Box flexGrow={1} sx={{ wordBreak: 'break-all', ...textSxProps.md }}>
            {props.condition.name}
          </Box>
          <Box pl={0.5} display="flex" alignItems="center">
            {props.newArrivalCount > 0 && (
              <Chip
                label={props.newArrivalCount > 99 ? '99+' : props.newArrivalCount}
                color="primary"
                size="small"
                sx={{
                  minWidth: '2rem',
                  background: theme => theme.palette.attention.main,
                  ...textSxProps.sm,
                  ...textSxProps.bold,
                }}
              />
            )}
            {!isSmallDevice && (
              <Box
                data-testclass="hiddenSaveConditionButtons"
                display={isHovered || props.isSelected ? 'block' : 'none'}
              >
                <IconButton
                  data-testclass="smallBudge"
                  size="small"
                  edge="end"
                  onClick={(e: ReactMouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    props.onMenuClick(e, props.condition);
                  }}
                  sx={{ padding: '2px' }}
                >
                  <DotsVertical />
                </IconButton>
              </Box>
            )}
          </Box>
        </Box>
      </ListItemText>
    </ListItemButton>
  );
}, deepCompare);
