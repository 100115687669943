import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
export const ConfirmationDialog = props => {
    return (_jsxs(Dialog, Object.assign({ open: props.isOpen, onClose: props.onClose, "aria-labelledby": "form-dialog-title", fullWidth: true, maxWidth: "sm" }, { children: [_jsx(DialogTitle, Object.assign({ id: "form-dialog-title" }, { children: props.title })), _jsx(DialogContent, { children: _jsx(DialogContentText, { children: props.contentText }) }), _jsxs(DialogActions, { children: [_jsx(Button, Object.assign({ onClick: props.onClose, variant: "outlined", sx: {
                            width: '6rem',
                        } }, { children: "\u30AD\u30E3\u30F3\u30BB\u30EB" })), _jsx(Button, Object.assign({ onClick: () => {
                            props.onSubmit();
                            props.onClose();
                        }, variant: "contained", color: "primary", sx: {
                            width: '6rem',
                        } }, { children: props.submitButtonText }))] })] })));
};
