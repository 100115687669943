import { TextFormDialog } from '@lib/components';
import { FC } from 'react';

type RenameDialogProps = {
  isDialogOpen: boolean;
  onDialogClose: () => void;
  onSubmit: (text: string) => void;
  defaultValue: string;
};

export const RenameDialog: FC<RenameDialogProps> = props => {
  return (
    <TextFormDialog
      isOpen={props.isDialogOpen}
      onClose={props.onDialogClose}
      onSubmit={props.onSubmit}
      title="検索条件名を変更する"
      contentText="検索条件に名前を付けて保存してください"
      formLabelText="条件名"
      onErrorText="条件名の入力は必須です"
      submitButtonText="保存"
      defaultValue={props.defaultValue}
    />
  );
};
