import { EsaDomain, EsaPublicDomain } from '@/Models/Esa/EsaDomain';
import { LoginContext } from '@/Models/Esa/EsaLoginContext';
import { EsaOrganization, EsaPublicOrganization } from '@/Models/Esa/EsaOrganization';
import { EsaUser } from '@/Models/Esa/EsaUser';
import { Partner } from '@/Models/Esa/Partner';
import { PartnerApplicants } from '@/Models/Esa/PartnerApplicants';

export const emptyLoginContext: LoginContext = {
  clientIp: '',
  currentUserUid: '',
  defaultUserUid: '',
  email: '',
  isAgreeTerms: false,
  providerUserUid: '',
  users: [],
};

const policy = {
  key: '',
  scope: [],
};
export const emptyCurrentUser: EsaUser = {
  createdAt: '',
  defaultOrganizationUid: null,
  denwaList: [],
  domainUid: '',
  ebone: null,
  email: null,
  emailNorify: { createdAt: '', enabled: false, modifiedAt: '' },
  emailVerified: false,
  enableEntryFeature: false,
  enableIntegrationFeature: false,
  ipAddressList: [],
  lastLoginDatetime: null,
  modifiedAt: '',
  name: '',
  organizationUids: [],
  providerUserUid: null,
  publicName: null,
  role: {
    comment: null,
    createdAt: '',
    description: null,
    domainUid: '',
    ebone: null,
    isAdmin: false,
    modifiledAt: '',
    name: '',
    policy: {
      edit_esa_domain: policy,
      edit_esa_organization: policy,
      edit_esa_user: policy,
      add_esa_user: policy,
      edit_esa_dedit_authorityomain: policy,
      edit_kokyaku: policy,
      view_chinshaku_bukken: policy,
      edit_chinshaku_bukken: policy,
      publish_chinshaku_bukken: policy,
    },
    readonly: false,
    roleUid: '',
    securityDescriptors: [],
  },
  roleUid: '',
  userUid: '',
};

export const dummyCurrentUser: EsaUser = {
  createdAt: '2022-07-04T15:13:50.264+09:00',
  defaultOrganizationUid: '4sKoEKyw89XboykaiAlC',
  denwaList: [],
  domainUid: 'Vk6RA9ZnHgN7J1mUlwNz',
  ebone: null,
  email: 'yoshinori.okino@e-seikatsu.co.jp',
  emailNorify: {
    createdAt: '2022-07-04T15:13:50.264+09:00',
    enabled: true,
    modifiedAt: '2022-07-04T15:13:50.264+09:00',
  },
  emailVerified: false,
  enableEntryFeature: false,
  enableIntegrationFeature: false,
  ipAddressList: [],
  lastLoginDatetime: '2023-08-29T11:32:34.921617+09:00',
  modifiedAt: '2023-08-29T11:32:34.874821+09:00',
  name: '沖野 テスト',
  organizationUids: ['4sKoEKyw89XboykaiAlC'],
  providerUserUid: 'auth0|62c284f8a8b71d2cc9f2701f',
  publicName: '沖野 テスト',
  role: {
    comment: '管理者権限',
    createdAt: '2023-04-27T13:49:27.311972+09:00',
    description: null,
    domainUid: 'Vk6RA9ZnHgN7J1mUlwNz',
    ebone: null,
    isAdmin: true,
    modifiledAt: '2023-04-27T13:49:27.311972+09:00',
    name: '管理者',
    policy: {
      edit_esa_domain: policy,
      edit_esa_organization: policy,
      edit_esa_user: policy,
      add_esa_user: policy,
      edit_esa_dedit_authorityomain: policy,
      edit_kokyaku: policy,
      view_chinshaku_bukken: policy,
      edit_chinshaku_bukken: policy,
      publish_chinshaku_bukken: policy,
    },
    readonly: true,
    roleUid: 'a2379b3e-1e3b-42de-a46d-fcbaedd9e55b',
    securityDescriptors: [],
  },
  roleUid: 'a2379b3e-1e3b-42de-a46d-fcbaedd9e55b',
  userUid: 'lHj2WTe7HGbUHeeCKivcKgBKnFc2',
};

export const dummyRole = {
  comment: '社員権限',
  createdAt: '2023-04-27T13:49:27.311972+09:00',
  description: null,
  domainUid: 'Vk6RA9ZnHgN7J1mUlwNz',
  ebone: null,
  isAdmin: false,
  modifiledAt: '2023-04-27T13:49:27.311972+09:00',
  name: '社員',
  policy: {
    edit_esa_domain: policy,
    edit_esa_organization: policy,
    edit_esa_user: policy,
    add_esa_user: policy,
    edit_esa_dedit_authorityomain: policy,
    edit_kokyaku: policy,
    view_chinshaku_bukken: policy,
    edit_chinshaku_bukken: policy,
    publish_chinshaku_bukken: policy,
  },
  readonly: true,
  roleUid: 'a2379b3e-1e3b-42de-a46d-fcbaedd9e55b',
  securityDescriptors: [],
};

export const dummyLoginContext: LoginContext = {
  clientIp: '122.26.32.131',
  currentUserUid: 'lHj2WTe7HGbUHeeCKivcKgBKnFc2',
  defaultUserUid: 'lHj2WTe7HGbUHeeCKivcKgBKnFc2',
  email: 'taro@e-seikatsu.co.jp',
  isAgreeTerms: true,
  providerUserUid: 'auth0|62c284f8a8b71d2cc9f2701f',
  users: [dummyCurrentUser],
};

export const dummyDomain: EsaDomain = {
  autoApproval: {
    createdAt: '2022-04-18T09:49:36+09:00',
    enabled: true,
    emailDomains: ['e-seikatsu.co.jp'],
    modifiedAt: '2022-05-18T09:49:36+09:00',
  },
  createdAt: '2022-04-18T09:49:36+09:00',
  denwaList: [
    {
      denwaNumberShubetsu: '電話',
      denwaNumber: '03-1111-0000',
    },
    {
      denwaNumberShubetsu: 'FAX',
      denwaNumber: '03-1111-1111',
    },
  ],
  domainUid: 'B834HVdEctliaT06',
  ebone: null,
  enableIntegrationFeature: false,
  isPhoneVerified: false,
  logoImage: {
    fileUid: 'oz9ZXb8Y',
    url: 'https://www.e-seikatsu.info/material/img/idx_img02.min.jpg',
  },
  modifiedAt: '2022-05-18T09:49:36+09:00',
  name: '株式会社いい生活',
  phoneVerification: {
    createdAt: '2022-04-18T09:49:36+09:00',
    enabled: true,
    modifiedAt: '2022-04-18T09:49:36+09:00',
  },
  takkenLicense: {
    licensor: '国土交通大臣',
    updateCount: '99',
    number: '12345678',
  },
  takkenLicenseImage: {
    fileUid: 'a2439667-2d41-441a-8aef-844682980838',
    url: 'https://www.e-seikatsu.info/material/img/logo.png',
  },
};

export const dummyDomainList: EsaDomain[] = [
  {
    autoApproval: {
      createdAt: '2022-04-18T09:49:36+09:00',
      enabled: true,
      emailDomains: ['e-seikatsu.co.jp'],
      modifiedAt: '2022-05-18T09:49:36+09:00',
    },
    createdAt: '2022-04-18T09:49:36+09:00',
    denwaList: [
      {
        denwaNumberShubetsu: '電話',
        denwaNumber: '03-1111-0000',
      },
      {
        denwaNumberShubetsu: 'FAX',
        denwaNumber: '03-1111-1111',
      },
    ],
    domainUid: 'B834HVdEctliaT06',
    ebone: null,
    enableIntegrationFeature: false,
    isPhoneVerified: false,
    logoImage: {
      fileUid: 'oz9ZXb8Y',
      url: 'https://www.e-seikatsu.info/material/img/idx_img02.min.jpg',
    },
    modifiedAt: '2022-05-18T09:49:36+09:00',
    name: '株式会社いい生活',
    phoneVerification: {
      createdAt: '2022-04-18T09:49:36+09:00',
      enabled: true,
      modifiedAt: '2022-04-18T09:49:36+09:00',
    },
    takkenLicense: {
      licensor: '国土交通大臣',
      updateCount: '3',
      number: '123214093824',
    },
    takkenLicenseImage: {
      fileUid: 'a2439667-2d41-441a-8aef-844682980838',
      url: 'https://www.e-seikatsu.info/material/img/logo.png',
    },
  },
  {
    autoApproval: {
      createdAt: '2022-02-18T09:49:36+09:00',
      enabled: true,
      emailDomains: ['e-seikatsu.co.jp'],
      modifiedAt: '2022-02-1auto9T09:49:36+09:00',
    },
    createdAt: '2022-04-18T09:49:36+09:00',
    denwaList: [
      {
        denwaNumberShubetsu: '電話',
        denwaNumber: '0901112222',
      },
    ],
    domainUid: 'C834HVdEctliaT06',
    ebone: null,
    enableIntegrationFeature: true,
    isPhoneVerified: true,
    logoImage: {
      fileUid: 'oz9ZXb8Y',
      url: 'https://www.e-seikatsu.info/material/img/logo.png',
    },
    modifiedAt: '2022-04-18T09:49:36+09:00',
    name: '株式会社いい生活2',
    phoneVerification: {
      createdAt: '2022-02-18T09:49:36+09:00',
      enabled: false,
      modifiedAt: '2022-04-18T09:49:36+09:00',
    },
    takkenLicense: {
      licensor: '国土交通大臣',
      updateCount: '99',
      number: '99999999',
    },
    takkenLicenseImage: {
      fileUid: 'a2439667-2d41-441a-8aef-844682980838',
      url: 'https://www.e-seikatsu.info/material/img/logo.png',
    },
  },
  {
    autoApproval: {
      createdAt: '2022-04-18T09:49:36+09:00',
      enabled: true,
      emailDomains: ['e-seikatsu.co.jp'],
      modifiedAt: '2022-05-18T09:49:36+09:00',
    },
    createdAt: '2022-04-18T09:49:36+09:00',
    denwaList: [
      {
        denwaNumberShubetsu: 'FAX',
        denwaNumber: '04811112222',
      },
    ],
    domainUid: 'D834HVdEctliaT06',
    ebone: null,
    enableIntegrationFeature: false,
    isPhoneVerified: false,
    logoImage: {
      fileUid: 'oz9ZXb8Y',
      url: 'https://image.docbase.io/uploads/f61ac8e7-50ec-4050-87b7-d283dbb5553b-128x128c.png',
    },
    modifiedAt: '2022-05-18T09:49:36+09:00',
    name: '株式会社いい生活3',
    phoneVerification: {
      createdAt: '2022-04-18T09:49:36+09:00',
      enabled: true,
      modifiedAt: '2022-04-18T09:49:36+09:00',
    },
    takkenLicense: {
      licensor: '国土交通大臣',
      updateCount: '99',
      number: '12345678',
    },
    takkenLicenseImage: {
      fileUid: 'a2439667-2d41-441a-8aef-844682980838',
      url: 'https://www.e-seikatsu.info/material/img/logo.png',
    },
  },
];

export const dummyOrganization: EsaOrganization = {
  businessName: '本店屋号',
  createdAt: '2022-04-18T09:49:36+09:00',
  denwaList: [
    {
      denwaNumberShubetsu: '電話',
      denwaNumber: '03-1111-0000',
    },
    {
      denwaNumberShubetsu: 'FAX',
      denwaNumber: '03-1111-1111',
    },
  ],
  domainUid: 'B834HVdEctliaT06',
  ebone: null,
  isPublic: false,
  jusho: {
    yubinNumber: '1060047',
    jushoText: '東京都港区南麻布5丁目2番32号',
    nokoriJusho: '',
    gaikuGoto: '',
    jushoCode: '131030C6000',
    isDirectInput: false,
    azaChoume: '',
    cityName: '',
    ooazaTsuushou: '',
    prefectureName: '',
  },
  kameiDantaiList: [{ name: 'サンプル所属団体' }],
  modifiedAt: '2022-04-18T09:49:36+09:00',
  name: '本店',
  organizationUid: 'TOHNaCKSTjXt6oUW',
  partnerAutoApproval: {
    createdAt: '',
    enabled: false,
    modifiedAt: '',
  },
};

export const dummyOrganizationList: EsaOrganization[] = [
  {
    businessName: '本店屋号',
    createdAt: '2022-04-18T09:49:36+09:00',
    denwaList: [
      {
        denwaNumberShubetsu: '電話',
        denwaNumber: '03-1111-0000',
      },
      {
        denwaNumberShubetsu: 'FAX',
        denwaNumber: '03-1111-1111',
      },
    ],
    domainUid: 'B834HVdEctliaT06',
    ebone: null,
    isPublic: false,
    jusho: {
      yubinNumber: '1060047',
      jushoText: '東京都港区南麻布5丁目2番32号',
      nokoriJusho: '興和広尾ビル3F',
      gaikuGoto: '',
      jushoCode: '131030C6000',
      isDirectInput: false,
      azaChoume: '',
      cityName: '',
      ooazaTsuushou: '',
      prefectureName: '',
    },
    kameiDantaiList: [{ name: 'サンプル所属団体' }],
    modifiedAt: '2022-04-18T09:49:36+09:00',
    name: '本店',
    organizationUid: 'TOHNaCKSTjXt6oUW',
    partnerAutoApproval: {
      createdAt: '',
      enabled: false,
      modifiedAt: '',
    },
  },
  {
    businessName: '本店屋号',
    createdAt: '2022-04-18T09:49:36+09:00',
    denwaList: [
      {
        denwaNumberShubetsu: '電話',
        denwaNumber: '000000000',
      },
    ],
    domainUid: 'C834HVdEctliaT06',
    ebone: null,
    isPublic: false,
    jusho: {
      yubinNumber: '106-0047',
      jushoText: '東京都港区南麻布5丁目',
      nokoriJusho: null,
      gaikuGoto: null,
      jushoCode: '',
      isDirectInput: false,
      azaChoume: '',
      cityName: '',
      ooazaTsuushou: '',
      prefectureName: '',
    },
    kameiDantaiList: [{ name: 'サンプル所属団体' }],
    modifiedAt: '2022-04-18T09:49:36+09:00',
    name: '広尾店',
    organizationUid: 'UOHNaCKSTjXt6oUW',
    partnerAutoApproval: {
      createdAt: '',
      enabled: false,
      modifiedAt: '',
    },
  },
  {
    businessName: '本店屋号',
    createdAt: '2022-04-18T09:49:36+09:00',
    denwaList: [
      {
        denwaNumberShubetsu: 'FAX',
        denwaNumber: '01230000000',
      },
    ],
    domainUid: 'B834HVdEctliaT06',
    ebone: null,
    jusho: {
      yubinNumber: '3310054',
      jushoText: '埼玉県さいたま市西区',
      nokoriJusho: '〇〇',
      gaikuGoto: '999',
      jushoCode: '',
      isDirectInput: false,
      azaChoume: '',
      cityName: '',
      ooazaTsuushou: '',
      prefectureName: '',
    },
    kameiDantaiList: [{ name: 'サンプル所属団体' }],
    modifiedAt: '2022-04-18T09:49:36+09:00',
    name: '埼玉店',
    organizationUid: 'TOHNaCKSTjXt6oUW',
    isPublic: false,
    partnerAutoApproval: {
      createdAt: '',
      enabled: false,
      modifiedAt: '',
    },
  },
];

export const partnerDummy: Partner = {
  createdAt: '2022-04-18T09:49:36+09:00',
  domainUid: 'B834HVdEctliaT06',
  modifiedAt: '2022-04-18T09:49:36+09:00',
  organizationUid: 'TOHNaCKSTjXt6oUW',
  partnerDomain: {
    domainUid: 'B834HVdEctliaT06',
    logoImage: {
      fileUid: 'oz9ZXb8Y',
      url: 'https://www.e-seikatsu.info/material/img/idx_img02.min.jpg',
    },
    name: '株式会社いい生活',
    takkenLicense: {
      licensor: '国土交通大臣',
      updateCount: '99',
      number: '12345678',
    },
  },
  partnerDomainUid: 'B834HVdEctliaT06',
  partnerOrganization: {
    businessName: '屋号が入る',
    denwaList: [
      {
        denwaNumberShubetsu: '電話',
        denwaNumber: '03-000-0000',
      },
      {
        denwaNumberShubetsu: 'FAX',
        denwaNumber: '03-000-1111',
      },
    ],
    domainUid: 'B834HVdEctliaT06',
    jusho: {
      yubinNumber: '1060047',
      jushoText: '東京都港区南麻布5丁目2番32号',
      nokoriJusho: '興和広尾ビル3F',
      gaikuGoto: '',
      jushoCode: '131030C6000',
      isDirectInput: false,
      azaChoume: '',
      cityName: '',
      ooazaTsuushou: '',
      prefectureName: '',
    },
    name: '広尾店',
    organizationUid: 'TOHNaCKSTjXt6oUW',
    kameiDantaiList: [],
  },
  partnerOrganizationUid: 'TOHNaCKSTjXt6oUW',
  partnerUid: 'B834HVdEctliaT06',
};

export const partnerDummyList: Partner[] = [
  {
    createdAt: '2022-04-18T09:49:36+09:00',
    domainUid: 'B834HVdEctliaT06',
    modifiedAt: '2022-04-18T09:49:36+09:00',
    organizationUid: 'TOHNaCKSTjXt6oUW',
    partnerDomain: {
      domainUid: 'B834HVdEctliaT06',
      logoImage: {
        fileUid: 'oz9ZXb8Y',
        url: 'https://www.e-seikatsu.info/material/img/idx_img02.min.jpg',
      },
      name: '株式会社いい生活',
      takkenLicense: {
        licensor: '国土交通大臣',
        updateCount: '99',
        number: '12345678',
      },
    },
    partnerDomainUid: 'B834HVdEctliaT06',
    partnerOrganization: {
      businessName: '屋号が入る',
      denwaList: [
        {
          denwaNumberShubetsu: '電話',
          denwaNumber: '03-000-0000',
        },
        {
          denwaNumberShubetsu: 'FAX',
          denwaNumber: '03-000-1111',
        },
      ],
      domainUid: 'B834HVdEctliaT06',
      jusho: {
        yubinNumber: '1060047',
        jushoText: '東京都港区南麻布5丁目2番32号',
        nokoriJusho: '興和広尾ビル3F',
        gaikuGoto: '',
        jushoCode: '131030C6000',
        isDirectInput: false,
        azaChoume: '',
        cityName: '',
        ooazaTsuushou: '',
        prefectureName: '',
      },
      name: '本店',
      organizationUid: 'TOHNaCKSTjXt6oUW',
      kameiDantaiList: [],
    },
    partnerOrganizationUid: 'TOHNaCKSTjXt6oUW',
    partnerUid: 'B834HVdEctliaT06',
  },
  {
    createdAt: '2022-04-18T09:49:36+09:00',
    domainUid: 'B834HVdEctliaT06',
    modifiedAt: '2022-04-18T09:49:36+09:00',
    organizationUid: 'UOHNaCKSTjXt6oUW',
    partnerDomain: {
      domainUid: 'B834HVdEctliaT06',
      logoImage: {
        fileUid: 'oz9ZXb8Y',
        url: 'https://www.e-seikatsu.info/material/img/logo.png',
      },
      name: '株式会社いい生活',
      takkenLicense: {
        licensor: '国土交通大臣',
        updateCount: '99',
        number: '12345678',
      },
    },
    partnerDomainUid: 'B834HVdEctliaT06',
    partnerOrganization: {
      businessName: '屋号が入る',
      denwaList: [
        {
          denwaNumberShubetsu: '電話',
          denwaNumber: '0120-0000-0000',
        },
      ],
      domainUid: 'B834HVdEctliaT06',
      jusho: {
        yubinNumber: '1060047',
        jushoText: '東京都港区南麻布5丁目2番32号',
        nokoriJusho: '興和広尾ビル3F',
        gaikuGoto: '',
        jushoCode: '131030C6000',
        isDirectInput: false,
        azaChoume: '',
        cityName: '',
        ooazaTsuushou: '',
        prefectureName: '',
      },
      name: '広尾店',
      organizationUid: 'UOHNaCKSTjXt6oUW',
      kameiDantaiList: [],
    },
    partnerOrganizationUid: 'B834HVdEctliaT06',
    partnerUid: 'B834HVdEctliaT06',
  },
  {
    createdAt: '2022-04-18T09:49:36+09:00',
    domainUid: 'B834HVdEctliaT06',
    modifiedAt: '2022-04-18T09:49:36+09:00',
    organizationUid: 'VOHNaCKSTjXt6oUW',
    partnerDomain: {
      domainUid: 'B834HVdEctliaT06',
      logoImage: {
        fileUid: 'oz9ZXb8Y',
        url: '',
      },
      name: '株式会社いい生活3',
      takkenLicense: {
        licensor: '国土交通大臣',
        updateCount: '99',
        number: '12345678',
      },
    },
    partnerDomainUid: 'B834HVdEctliaT06',
    partnerOrganization: {
      businessName: '屋号が入る',
      denwaList: [
        {
          denwaNumberShubetsu: 'FAX',
          denwaNumber: '0123-0000-0000',
        },
      ],
      domainUid: 'C834HVdEctliaT06',
      jusho: {
        yubinNumber: '3310054',
        jushoText: '埼玉県さいたま市西区',
        nokoriJusho: '〇〇',
        gaikuGoto: '999',
        jushoCode: '',
        isDirectInput: false,
        azaChoume: '',
        cityName: '',
        ooazaTsuushou: '',
        prefectureName: '',
      },
      name: '埼玉店',
      organizationUid: 'VOHNaCKSTjXt6oUW',
      kameiDantaiList: [],
    },
    partnerOrganizationUid: 'C834HVdEctliaT06',
    partnerUid: 'C834HVdEctliaT06',
  },
];

export const esaPublicDomainDummy: EsaPublicDomain = {
  domainUid: 'B834HVdEctliaT06',
  logoImage: {
    fileUid: 'oz9ZXb8Y',
    url: 'https://www.e-seikatsu.info/material/img/idx_img02.min.jpg',
  },
  name: '株式会社いい生活',
  takkenLicense: {
    licensor: '国土交通大臣',
    updateCount: '99',
    number: '12345678',
  },
};

export const esaPublicDomainListDummy: EsaPublicDomain[] = [
  {
    domainUid: 'B834HVdEctliaT06',
    logoImage: {
      fileUid: 'oz9ZXb8Y',
      url: 'https://www.e-seikatsu.info/material/img/idx_img02.min.jpg',
    },
    name: '株式会社いい生活1',
    takkenLicense: {
      licensor: '国土交通大臣',
      updateCount: '99',
      number: '12345678',
    },
  },
  {
    domainUid: 'C834HVdEctliaT06',
    logoImage: {
      fileUid: 'oz9ZXb8Y',
      url: 'https://www.e-seikatsu.info/material/img/idx_img02.min.jpg',
    },
    name: '株式会社いい生活2',
    takkenLicense: {
      licensor: '国土交通大臣',
      updateCount: '99',
      number: '12345678',
    },
  },
  {
    domainUid: 'D834HVdEctliaT06',
    logoImage: {
      fileUid: 'oz9ZXb8Y',
      url: 'https://www.e-seikatsu.info/material/img/idx_img02.min.jpg',
    },
    name: '株式会社いい生活3',
    takkenLicense: {
      licensor: '国土交通大臣',
      updateCount: '99',
      number: '12345678',
    },
  },
];

export const esaPublicOrganizationDummy: EsaPublicOrganization = {
  domainUid: 'B834HVdEctliaT06',
  organizationUid: 'TOHNaCKSTjXt6oUW',
  businessName: '屋号が入る',
  denwaList: [
    {
      denwaNumberShubetsu: '電話',
      denwaNumber: '03-000-0000',
    },
    {
      denwaNumberShubetsu: 'FAX',
      denwaNumber: '03-000-1111',
    },
  ],
  jusho: {
    yubinNumber: '1060047',
    jushoText: '東京都港区南麻布5丁目2番32号',
    nokoriJusho: '興和広尾ビル3F',
    gaikuGoto: '',
    jushoCode: '131030C6000',
    isDirectInput: false,
    azaChoume: '',
    cityName: '',
    ooazaTsuushou: '',
    prefectureName: '',
  },
  name: '広尾店',
  kameiDantaiList: [],
};

export const esaPublicOrganizationListDummy: EsaPublicOrganization[] = [
  {
    domainUid: 'B834HVdEctliaT06',
    organizationUid: 'TOHNaCKSTjXt6oUW',
    businessName: '屋号が入る',
    denwaList: [
      {
        denwaNumberShubetsu: '電話',
        denwaNumber: '03-000-0000',
      },
      {
        denwaNumberShubetsu: 'FAX',
        denwaNumber: '03-000-1111',
      },
    ],
    jusho: {
      yubinNumber: '1060047',
      jushoText: '東京都港区南麻布5丁目2番32号',
      nokoriJusho: '興和広尾ビル3F',
      gaikuGoto: '',
      jushoCode: '131030C6000',
      isDirectInput: false,
      azaChoume: '',
      cityName: '',
      ooazaTsuushou: '',
      prefectureName: '',
    },
    name: '本店',
    kameiDantaiList: [],
  },
  {
    domainUid: 'B834HVdEctliaT06',
    organizationUid: 'UOHNaCKSTjXt6oUW',
    businessName: '屋号が入る',
    denwaList: [
      {
        denwaNumberShubetsu: '電話',
        denwaNumber: '03-000-0000',
      },
      {
        denwaNumberShubetsu: 'FAX',
        denwaNumber: '03-000-1111',
      },
    ],
    jusho: {
      yubinNumber: '1060047',
      jushoText: '東京都港区南麻布5丁目2番32号',
      nokoriJusho: '興和広尾ビル3F',
      gaikuGoto: '',
      jushoCode: '131030C6000',
      isDirectInput: false,
      azaChoume: '',
      cityName: '',
      ooazaTsuushou: '',
      prefectureName: '',
    },
    name: '広尾店',
    kameiDantaiList: [],
  },
  {
    domainUid: 'B834HVdEctliaT06',
    organizationUid: 'VOHNaCKSTjXt6oUW',
    businessName: '屋号が入る',
    denwaList: [
      {
        denwaNumberShubetsu: '電話',
        denwaNumber: '03-000-0000',
      },
      {
        denwaNumberShubetsu: 'FAX',
        denwaNumber: '03-000-1111',
      },
    ],
    jusho: {
      yubinNumber: '1060047',
      jushoText: '東京都港区南麻布5丁目2番32号',
      nokoriJusho: '興和広尾ビル3F',
      gaikuGoto: '',
      jushoCode: '131030C6000',
      isDirectInput: false,
      azaChoume: '',
      cityName: '',
      ooazaTsuushou: '',
      prefectureName: '',
    },
    name: 'あああ店',
    kameiDantaiList: [],
  },
];

export const esaPublicDomainOrganizationDummy: EsaPublicOrganization & { domain: EsaPublicDomain } = {
  domainUid: 'B834HVdEctliaT06',
  domain: {
    domainUid: 'B834HVdEctliaT06',
    logoImage: {
      fileUid: 'oz9ZXb8Y',
      url: 'https://www.e-seikatsu.info/material/img/idx_img02.min.jpg',
    },
    name: '株式会社いい生活',
    takkenLicense: {
      licensor: '国土交通大臣',
      updateCount: '99',
      number: '12345678',
    },
  },
  organizationUid: 'TOHNaCKSTjXt6oUW',
  businessName: '屋号が入る',
  denwaList: [
    {
      denwaNumberShubetsu: '電話',
      denwaNumber: '03-000-0000',
    },
    {
      denwaNumberShubetsu: 'FAX',
      denwaNumber: '03-000-1111',
    },
  ],
  jusho: {
    yubinNumber: '1060047',
    jushoText: '東京都港区南麻布5丁目2番32号',
    nokoriJusho: '興和広尾ビル3F',
    gaikuGoto: '',
    jushoCode: '131030C6000',
    isDirectInput: false,
    azaChoume: '',
    cityName: '',
    ooazaTsuushou: '',
    prefectureName: '',
  },
  name: '広尾店',
  kameiDantaiList: [],
};

export const PartnerApplicantsDummy: PartnerApplicants = {
  approvalComment: '',
  cancellationComment: '',
  comment: 'よろしくお願いします',
  createdAt: '2023-10-12T13:50:04.036361+09:00',
  domainUid: 'Vk6RA9ZnHgN7J1mUlwNz',
  domain: {
    domainUid: 'Vk6RA9ZnHgN7J1mUlwNz',
    logoImage: {
      fileUid: '37e11f98-44ef-4eff-9d1e-ad1378c9f7a2',
      url: 'https://app.stg.es-account.com/api/domains/Vk6RA9ZnHgN7J1mUlwNz/logo_image',
    },
    name: '沖野研修不動産',
    takkenLicense: { licensor: '北海道知事', updateCount: '02', number: '000022' },
  },
  modifiedAt: '2023-10-12T13:54:30.450902+09:00',
  organizationUid: '',
  organization: {
    businessName: null,
    denwaList: [{ denwaNumber: '03-0000-0000', denwaNumberShubetsu: 'FAX' }],
    domainUid: 'Vk6RA9ZnHgN7J1mUlwNz',
    jusho: {
      azaChoume: null,
      cityName: null,
      gaikuGoto: '',
      isDirectInput: true,
      jushoCode: '',
      jushoText: '東京都港区南麻布２丁目',
      nokoriJusho: '3',
      ooazaTsuushou: null,
      prefectureName: null,
      yubinNumber: '1060047',
    },
    kameiDantaiList: [{ name: '全日本' }],
    name: '本店',
    organizationUid: '4sKoEKyw89XboykaiAlC',
  },
  partnerApplicantUid: 'e2470d52-8d7b-4c5e-ad99-6c4e7fa56215',
  partnerDomain: {
    domainUid: '2KFhWKyyDNu5ySLNkIaW',
    logoImage: null,
    name: 'サンプル不動産A111',
    takkenLicense: { licensor: '東京都知事', updateCount: '01', number: '123456' },
  },
  partnerDomainUid: '2KFhWKyyDNu5ySLNkIaW',
  partnerOrganization: {
    businessName: null,
    denwaList: [],
    domainUid: '2KFhWKyyDNu5ySLNkIaW',
    jusho: {
      azaChoume: null,
      cityName: null,
      gaikuGoto: '',
      isDirectInput: true,
      jushoCode: '',
      jushoText: '東京都港区南麻布２丁目',
      nokoriJusho: '3',
      ooazaTsuushou: null,
      prefectureName: null,
      yubinNumber: '1060047',
    },
    kameiDantaiList: [],
    name: '組織名221215',
    organizationUid: 'QQLblhYduBENImZw6SnL',
  },
  partnerOrganizationUid: 'QQLblhYduBENImZw6SnL',
  rejectionComment: '',
  state: 'approved',
  email: 'y@e-seikatsu.co.jp',
  userName: 'いい生活ユーザー',
};

export const PartnerApplicantsListDummy: PartnerApplicants[] = [
  {
    approvalComment: '',
    cancellationComment: '',
    comment: 'よろしくお願いします',
    createdAt: '2023-10-12T13:50:04.036361+09:00',
    domainUid: 'Vk6RA9ZnHgN7J1mUlwNz',
    domain: {
      domainUid: 'Vk6RA9ZnHgN7J1mUlwNz',
      logoImage: {
        fileUid: '37e11f98-44ef-4eff-9d1e-ad1378c9f7a2',
        url: 'https://app.stg.es-account.com/api/domains/Vk6RA9ZnHgN7J1mUlwNz/logo_image',
      },
      name: '沖野研修不動産',
      takkenLicense: { licensor: '北海道知事', updateCount: '02', number: '000022' },
    },
    modifiedAt: '2023-10-12T13:54:30.450902+09:00',
    organizationUid: '',
    organization: {
      businessName: null,
      denwaList: [{ denwaNumber: '03-0000-0000', denwaNumberShubetsu: 'FAX' }],
      domainUid: 'Vk6RA9ZnHgN7J1mUlwNz',
      jusho: {
        azaChoume: null,
        cityName: null,
        gaikuGoto: '',
        isDirectInput: true,
        jushoCode: '',
        jushoText: '東京都港区南麻布２丁目',
        nokoriJusho: '3',
        ooazaTsuushou: null,
        prefectureName: null,
        yubinNumber: '1060047',
      },
      kameiDantaiList: [{ name: '全日本' }],
      name: '本店',
      organizationUid: '4sKoEKyw89XboykaiAlC',
    },
    partnerApplicantUid: 'e2470d52-8d7b-4c5e-ad99-6c4e7fa56215',
    partnerDomain: {
      domainUid: '2KFhWKyyDNu5ySLNkIaW',
      logoImage: null,
      name: 'サンプル不動産A111',
      takkenLicense: { licensor: '東京都知事', updateCount: '01', number: '123456' },
    },
    partnerDomainUid: '2KFhWKyyDNu5ySLNkIaW',
    partnerOrganization: {
      businessName: null,
      denwaList: [],
      domainUid: '2KFhWKyyDNu5ySLNkIaW',
      jusho: {
        azaChoume: null,
        cityName: null,
        gaikuGoto: '',
        isDirectInput: true,
        jushoCode: '',
        jushoText: '東京都港区南麻布２丁目',
        nokoriJusho: '3',
        ooazaTsuushou: null,
        prefectureName: null,
        yubinNumber: '1060047',
      },
      kameiDantaiList: [],
      name: '組織名221215',
      organizationUid: 'QQLblhYduBENImZw6SnL',
    },
    partnerOrganizationUid: 'QQLblhYduBENImZw6SnL',
    rejectionComment: '',
    state: 'approved',
    email: 'y@e-seikatsu.co.jp',
    userName: 'いい生活ユーザー',
  },
  {
    approvalComment: '',
    cancellationComment: '',
    comment: 'よろしくお願いします',
    createdAt: '2023-10-12T13:50:04.036361+09:00',
    domainUid: 'Vk6RA9ZnHgN7J1mUlwNz',
    domain: {
      domainUid: 'Vk6RA9ZnHgN7J1mUlwNz',
      logoImage: {
        fileUid: '37e11f98-44ef-4eff-9d1e-ad1378c9f7a2',
        url: 'https://app.stg.es-account.com/api/domains/Vk6RA9ZnHgN7J1mUlwNz/logo_image',
      },
      name: '沖野研修不動産',
      takkenLicense: { licensor: '北海道知事', updateCount: '02', number: '000022' },
    },
    modifiedAt: '2023-10-12T13:54:30.450902+09:00',
    organizationUid: '',
    organization: {
      businessName: null,
      denwaList: [{ denwaNumber: '03-0000-0000', denwaNumberShubetsu: 'FAX' }],
      domainUid: 'Vk6RA9ZnHgN7J1mUlwNz',
      jusho: {
        azaChoume: null,
        cityName: null,
        gaikuGoto: '',
        isDirectInput: true,
        jushoCode: '',
        jushoText: '東京都港区南麻布２丁目',
        nokoriJusho: '3',
        ooazaTsuushou: null,
        prefectureName: null,
        yubinNumber: '1060047',
      },
      kameiDantaiList: [{ name: '全日本' }],
      name: '本店',
      organizationUid: '4sKoEKyw89XboykaiAlC',
    },
    partnerApplicantUid: 'e2470d52-8d7b-4c5e-ad99-6c4e7fa56215',
    partnerDomain: {
      domainUid: '2KFhWKyyDNu5ySLNkIaW',
      logoImage: null,
      name: 'サンプル不動産A111',
      takkenLicense: { licensor: '東京都知事', updateCount: '01', number: '123456' },
    },
    partnerDomainUid: '2KFhWKyyDNu5ySLNkIaW',
    partnerOrganization: {
      businessName: null,
      denwaList: [],
      domainUid: '2KFhWKyyDNu5ySLNkIaW',
      jusho: {
        azaChoume: null,
        cityName: null,
        gaikuGoto: '',
        isDirectInput: true,
        jushoCode: '',
        jushoText: '東京都港区南麻布２丁目',
        nokoriJusho: '3',
        ooazaTsuushou: null,
        prefectureName: null,
        yubinNumber: '1060047',
      },
      kameiDantaiList: [],
      name: '組織名221215',
      organizationUid: 'QQLblhYduBENImZw6SnL',
    },
    partnerOrganizationUid: 'QQLblhYduBENImZw6SnL',
    rejectionComment: '',
    state: 'standby',
    email: 'y@e-seikatsu.co.jp',
    userName: 'いい生活ユーザー',
  },
  {
    approvalComment: '',
    cancellationComment: '',
    comment: 'よろしくお願いします',
    createdAt: '2023-10-12T13:50:04.036361+09:00',
    domainUid: 'Vk6RA9ZnHgN7J1mUlwNz',
    domain: {
      domainUid: 'Vk6RA9ZnHgN7J1mUlwNz',
      logoImage: {
        fileUid: '37e11f98-44ef-4eff-9d1e-ad1378c9f7a2',
        url: 'https://app.stg.es-account.com/api/domains/Vk6RA9ZnHgN7J1mUlwNz/logo_image',
      },
      name: '沖野研修不動産',
      takkenLicense: { licensor: '北海道知事', updateCount: '02', number: '000022' },
    },
    modifiedAt: '2023-10-12T13:54:30.450902+09:00',
    organizationUid: '',
    organization: {
      businessName: null,
      denwaList: [{ denwaNumber: '03-0000-0000', denwaNumberShubetsu: 'FAX' }],
      domainUid: 'Vk6RA9ZnHgN7J1mUlwNz',
      jusho: {
        azaChoume: null,
        cityName: null,
        gaikuGoto: '',
        isDirectInput: true,
        jushoCode: '',
        jushoText: '東京都港区南麻布２丁目',
        nokoriJusho: '3',
        ooazaTsuushou: null,
        prefectureName: null,
        yubinNumber: '1060047',
      },
      kameiDantaiList: [{ name: '全日本' }],
      name: '本店',
      organizationUid: '4sKoEKyw89XboykaiAlC',
    },
    partnerApplicantUid: 'e2470d52-8d7b-4c5e-ad99-6c4e7fa56215',
    partnerDomain: {
      domainUid: '2KFhWKyyDNu5ySLNkIaW',
      logoImage: null,
      name: 'サンプル不動産A111',
      takkenLicense: { licensor: '東京都知事', updateCount: '01', number: '123456' },
    },
    partnerDomainUid: '2KFhWKyyDNu5ySLNkIaW',
    partnerOrganization: {
      businessName: null,
      denwaList: [],
      domainUid: '2KFhWKyyDNu5ySLNkIaW',
      jusho: {
        azaChoume: null,
        cityName: null,
        gaikuGoto: '',
        isDirectInput: true,
        jushoCode: '',
        jushoText: '東京都港区南麻布２丁目',
        nokoriJusho: '3',
        ooazaTsuushou: null,
        prefectureName: null,
        yubinNumber: '1060047',
      },
      kameiDantaiList: [],
      name: '組織名221215',
      organizationUid: 'QQLblhYduBENImZw6SnL',
    },
    partnerOrganizationUid: 'QQLblhYduBENImZw6SnL',
    rejectionComment: '',
    state: 'rejected',
    email: 'y@e-seikatsu.co.jp',
    userName: 'いい生活ユーザー',
  },
];
