import { ConfirmationDialog } from '@lib/components';
import { FC } from 'react';

type DeleteDialogProps = {
  isDialogOpen: boolean;
  onDialogClose: () => void;
  onSubmit: () => void;
  selectedConditionName: string;
};

export const DeleteDialog: FC<DeleteDialogProps> = props => {
  return (
    <ConfirmationDialog
      isOpen={props.isDialogOpen}
      onClose={props.onDialogClose}
      onSubmit={props.onSubmit}
      title="検索条件を削除する"
      contentText={`検索条件「${props.selectedConditionName}」を本当に削除してよろしいですか？`}
      submitButtonText="削除する"
    />
  );
};
